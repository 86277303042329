<template>
  <div >
    <div class="row mt-2 mx-2">
      <div class="col-12">
        <v-dialog v-model="dialog" persistent width="40%">
          <template v-slot:activator="{ on: dialog }">
            <v-btn
              color="white"
              elevation="1"
              dark
              v-on="{ ...dialog }"
            >
              <span class="primary--text">
                <v-icon>mdi-format-list-bulleted-type</v-icon>
              </span>
              <span class="primary--text"> ADD CATEGORY </span>
            </v-btn>
          </template>
          <v-card color="rgba(255,255,255,1)">
            <v-toolbar elevation="0" color="#f2f2f2">
              <v-card-title class="text-h5 text-center">
                <h5 class="text-center primary--text">Add survey category</h5>
              </v-card-title>
            </v-toolbar>
            <v-card-text class="mt-3">
              <v-text-field
                label="category name"
                v-model="categoryName"
                outlined
                dense
              />
            </v-card-text>
            <v-card-actions class="mx-3">
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                class="white--text"
                @click="dialog = false"
                style="text-transform: unset"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                class="white--text"
                @click="addSurveyCategory()"
                style="text-transform: unset"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="row mt-2 mx-2">
      <div class="col-12">
        <v-card elevation="1">
          <v-tabs v-model="tab" background-color="#f2f2f2">
            <v-tab @click="getSurveyCategories()">
              <h1 color="blue--text">
                <v-icon>mdi-format-list-bulleted-type</v-icon> Survey & quiz categories
              </h1>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card elevation="2">
                <v-card-title class="primary--text">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  />
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="surveyCategories"
                  :search="search"
                >
                  <template v-slot:[`item.SN`]="{ item }">
                    {{ surveyCategories.indexOf(item) + 1 }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      slot="activator"
                      color="primary"
                      icon
                      @click="deleteSurveyCategory(item.categoryUniqueId)"
                      v-if="hasAccess('CAN_DELETE_SURVEY_CATEGORY')"
                    >
                      <v-icon color="red"> mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </div>
  </div>
</template>


<script>

import { mapGetters } from "vuex";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      dialog: false,
      categoryName: "",
      search: "",
      tab: "",
      headers: [
        {
          text: "SN",
          align: "start",
          sortable: false,
          value: "SN",
        },
        { text: "category name", value: "categoryName" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    addSurveyCategory() {
      this.$store.dispatch("addSurveyCategory", {
        categoryName: this.categoryName,
      });
      this.categoryName = "";
      this.dialog = false;
    },

    deleteSurveyCategory(categoryUid) {
      Swal.fire({
          title: `<h6 style="color: grey">Do you want to delete category?</h6>`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: 'red',
          cancelButtonColor: 'blue'
      }).then((data)=>{
        if(data?.isConfirmed){
            this.$store.dispatch("deleteSurveyCategory", {
              categoryUid: categoryUid,
            });
          }
      })
    },

    getSurveyCategories() {
      this.$store.dispatch("fetchAllSurveyCategories");
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchAllSurveyCategories");
  },
  computed: {
    ...mapGetters({
      surveyCategories: "getAllSurveyCategories",
    }),
  },
};
</script>

<style scoped>
.background {
  background-color: aliceblue;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
